type PromiseReturningFunction = () => any;

export function retry(fn: PromiseReturningFunction, retriesLeft = 2, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            window.location.reload();
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function getExtension(name: string): string {
  const decomposition = name.split('.');
  if (decomposition.length) {
    return decomposition[decomposition.length - 1].toLowerCase();
  }
  return '';
}

export function getIconFromExtension(extension: string): string {
  // https://en.wikipedia.org/wiki/List_of_Microsoft_Office_filename_extensions
  const word = ['doc', 'docx', 'docm', 'dotx'];
  const zip = ['zip'];
  const excel = ['xls', 'xlsx', 'xlsm', 'xltx', 'csv'];
  const pdf = ['pdf'];
  const image = ['jpeg', 'gif', 'jpg', 'png'];
  extension = extension.toLowerCase();
  const ppt = ['pptx'];
  if (word.includes(extension)) {
    return 'word';
  }
  if (zip.includes(extension)) {
    return 'zip';
  }
  if (excel.includes(extension)) {
    return 'excel';
  }
  if (pdf.includes(extension)) {
    return 'pdf';
  }
  if (image.includes(extension)) {
    return 'img';
  }
  if (ppt.includes(extension)) {
    return 'power_point';
  }
  return 'file';
}

export function getNestedValue<T>(obj: Record<string, any>, path: string): T | undefined {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj) as T | undefined;
}

export function isKeyGreaterThanZero(object:any, key:string) {
  return (object?.[key] ?? 0) > 0;
}
